const scenario = [
  {
    scenario: {
      patientDetails: {
        title: "Traumatic Airway Injury in the ER for 45-Year-Old Man",
        name: "Michael Brown",
        age: 45,
        active: true,
        gender: "Male",
        weight: "80 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Hypertension",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 45-year-old male, arrived at the ER after a motor vehicle collision. He presented with significant swelling and deformity of the neck and difficulty breathing. Examination revealed subcutaneous emphysema, hoarseness, and stridor, indicating potential airway compromise.",
        vitalSigns: {
          heartRate: "110 bpm",
          respiratoryRate: "30 breaths/min",
          oxygenSaturation: "88%",
          bloodPressure: "140/90 mmHg",
          description:
            "The patient, a 45-year-old male, arrived at the emergency room following a high-impact motor vehicle collision, presenting with concerning signs of traumatic airway injury.On examination, his neck exhibited significant swelling and deformity, accompanied by subcutaneous emphysema—a hallmark of air leakage into the subcutaneous tissue, often indicative of tracheal or laryngeal disruption. Additionally, the patient displayed hoarseness and stridor, clear signs of partial airway obstruction and vocal cord involvement. These findings suggested an urgent risk of airway compromise.The immediate priority in this scenario is securing the airway. Stridor indicates turbulent airflow, which, combined with progressive swelling, raises the potential for complete obstruction. An awake fiberoptic intubation or surgical airway, such as a cricothyrotomy, may be considered based on the severity and anatomical distortions.Simultaneously, the patient’s cervical spine stability must be presumed compromised, necessitating precautions during airway management.This clinical presentation underscores the critical nature of prompt assessment and intervention in traumatic airway injuries. A multidisciplinary approach involving emergency physicians, anesthesiologists, and otolaryngologists is essential.Imaging, such as a CT scan, may assist in delineating the extent of injury post-stabilization. Early airway management and vigilant monitoring are pivotal to preventing respiratory failure and optimizing patient outcomes.",
        },
        symptoms: ["Subcutaneous emphysema", "Hoarseness", "Stridor"],
      },
      management: {
        initialActions: ["Administered high-flow oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Traumatic airway injury",
            ETTubeSize: "7.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Allergic Reaction in the ER for 40-Year-Old Man",
        name: "Robert Miller",
        age: 40,
        active: true,
        gender: "Male",
        weight: "85 kg",
        medicalHistory: {
          knownAllergies: "Bee stings",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 40-year-old male, presented with swelling of the face and throat, difficulty breathing, and urticaria following a bee sting. Examination revealed stridor and signs of anaphylactic shock, including hypotension and cyanosis.",
        vitalSigns: {
          heartRate: "150 bpm",
          respiratoryRate: "40 breaths/min",
          oxygenSaturation: "80%",
          bloodPressure: "75/50 mmHg",
          description:
            "A 40-year-old male presented to the emergency room following a bee sting, exhibiting symptoms of a severe allergic reaction. His condition was characterized by marked swelling of the face and throat, accompanied by difficulty breathing and urticaria (hives). Examination revealed stridor, indicating upper airway obstruction, and systemic signs of anaphylaxis, including hypotension and cyanosis, pointing to compromised circulation and oxygenation.Anaphylaxis is a life-threatening hypersensitivity reaction that requires immediate intervention. The patient's stridor and throat swelling suggest impending airway obstruction, while hypotension and cyanosis indicate distributive shock and inadequate tissue perfusion. Rapid administration of intramuscular epinephrine is the cornerstone of treatment, reversing airway and circulatory compromise by reducing swelling, increasing vascular tone, and improving cardiac output.Supplemental oxygen should be provided, and intravenous access is critical for administering fluids to correct hypotension and maintain perfusion. Adjunct treatments, such as antihistamines and corticosteroids, can help manage symptoms but do not replace the need for epinephrine.This case underscores the importance of timely diagnosis and intervention in anaphylaxis to prevent irreversible complications. Ongoing monitoring in a critical care setting is essential, as biphasic reactions can occur even after initial stabilization. Multidisciplinary support ensures comprehensive care and improved patient outcomes.",
        },
        symptoms: ["Facial swelling", "Stridor", "Urticaria"],
      },
      management: {
        initialActions: ["Administered intramuscular epinephrine (0.5 mg)"],
        interventions: {
          nebulizedMedications: { medication: "Salbutamol", dose: "2.5 mg" },
          intubation: {
            indication: "Airway compromise due to anaphylaxis",
            ETTubeSize: "7.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Smoke Inhalation Injury in the ER for 38-Year-Old Man",
        name: "Chris Taylor",
        age: 38,
        active: true,
        gender: "Male",
        weight: "78 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 38-year-old male, presented to the ER after being rescued from a house fire. He had hoarseness, stridor, and soot in the oropharynx. Examination revealed severe respiratory distress and signs of thermal injury to the airway.",
        vitalSigns: {
          heartRate: "125 bpm",
          respiratoryRate: "35 breaths/min",
          oxygenSaturation: "84%",
          bloodPressure: "100/70 mmHg",
          description:
            "A 38-year-old male arrived at the emergency room after being rescued from a house fire, presenting with symptoms indicative of significant smoke inhalation and airway injury. He exhibited hoarseness and stridor, suggesting upper airway obstruction, and soot was visible in his oropharynx, confirming exposure to smoke and thermal insult. The patient was in severe respiratory distress, with labored breathing and hypoxia, further highlighting the urgency of the situation. Examination also revealed signs of thermal injury to the airway, including mucosal erythema and edema.Smoke inhalation injuries can involve thermal damage to the upper airway, chemical irritation from toxic gases, and hypoxemia due to carbon monoxide or cyanide poisoning. In this patient, the presence of stridor and hoarseness raises concerns about impending airway compromise due to progressive swelling. Immediate airway management is critical, with early intubation being the preferred approach to secure the airway and prevent complete obstruction.High-flow oxygen should be administered to address hypoxia, and carboxyhemoglobin levels should be assessed to evaluate carbon monoxide exposure. Adjunctive therapies, such as bronchodilators and humidified oxygen, can help alleviate lower airway inflammation. Continuous monitoring in a critical care setting is essential, as airway edema and respiratory compromise can worsen over time, necessitating multidisciplinary intervention.",
        },
        symptoms: ["Hoarseness", "Stridor", "Soot in oropharynx"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway swelling from thermal injury",
            ETTubeSize: "8.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Anaphylaxis in the ER for 32-Year-Old Woman",
        name: "Emily Johnson",
        age: 32,
        active: false,
        gender: "Female",
        weight: "60 kg",
        medicalHistory: {
          knownAllergies: "Peanuts",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 32-year-old female, arrived at the ER with swelling of the face, lips, and throat, following accidental ingestion of peanuts. She exhibited severe respiratory distress with audible stridor and widespread urticaria. Additional signs included cyanosis and hypotension.",
        vitalSigns: {
          heartRate: "145 bpm",
          respiratoryRate: "40 breaths/min",
          oxygenSaturation: "82%",
          bloodPressure: "75/50 mmHg",
          description:
            "A 32-year-old female presented to the emergency room with severe anaphylaxis following accidental peanut ingestion. She exhibited swelling of the face, lips, and throat, leading to significant airway obstruction. Audible stridor and severe respiratory distress highlighted the critical nature of her condition. Examination revealed widespread urticaria, cyanosis, and hypotension, indicating systemic involvement and impending cardiovascular collapse.Anaphylaxis is a severe, life-threatening allergic reaction characterized by rapid onset and multi-system involvement. The patient’s airway swelling and stridor suggest imminent risk of airway obstruction, while hypotension and cyanosis point to distributive shock and insufficient oxygen delivery to tissues. Immediate administration of intramuscular epinephrine is essential to counteract airway swelling, restore vascular tone, and improve respiratory and cardiovascular function.High-flow oxygen should be provided to address hypoxia, and intravenous access is critical for fluid resuscitation to manage hypotension. Adjunct treatments, including antihistamines and corticosteroids, may help reduce inflammation and prevent a protracted reaction but are secondary to epinephrine. Continuous monitoring in a high-acuity setting is necessary due to the risk of biphasic anaphylaxis or delayed complications.This case underscores the urgency of early recognition and aggressive management of anaphylaxis to prevent catastrophic outcomes, with a focus on securing the airway, stabilizing circulation, and mitigating systemic effects.",
        },
        symptoms: ["Facial swelling", "Stridor", "Cyanosis"],
      },
      management: {
        initialActions: ["Administered intramuscular epinephrine (0.3 mg)"],
        interventions: {
          nebulizedMedications: { medication: "Salbutamol", dose: "2.5 mg" },
          intubation: {
            indication: "Severe airway swelling",
            ETTubeSize: "6.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Post-Surgical Airway Edema in the ER for 28-Year-Old Woman",
        name: "Sophia Carter",
        age: 28,
        active: false,
        gender: "Female",
        weight: "65 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 28-year-old female, presented to the ER with progressive difficulty breathing following neck surgery performed earlier in the day. Examination revealed hoarseness, stridor, and swelling of the neck. Her oxygen saturation was declining despite supplemental oxygen.",
        vitalSigns: {
          heartRate: "120 bpm",
          respiratoryRate: "28 breaths/min",
          oxygenSaturation: "88%",
          bloodPressure: "110/65 mmHg",
          description:
            "A 28-year-old female presented to the emergency room with progressive difficulty breathing following neck surgery earlier the same day. She exhibited hoarseness, stridor, and visible neck swelling, indicative of post-surgical airway edema. Her oxygen saturation was noted to be declining despite supplemental oxygen, signaling worsening respiratory compromise and an urgent need for intervention.Post-surgical airway edema is a recognized complication of neck surgeries, often resulting from tissue manipulation, hematoma formation, or inflammatory responses. In this case, stridor reflects turbulent airflow caused by airway narrowing, while hoarseness suggests possible laryngeal involvement. The patient’s declining oxygen saturation underscores the critical nature of her condition, with the potential for rapid progression to complete airway obstruction.The immediate priority is securing the airway. Depending on the degree of swelling, endotracheal intubation using fiberoptic guidance may be attempted. However, if intubation is deemed unsafe due to severe distortion, a surgical airway, such as an emergency cricothyrotomy, may be necessary. Concurrently, steps should be taken to reduce the edema, including intravenous corticosteroids and diuretics, as clinically indicated.This case highlights the importance of vigilant monitoring and timely intervention for post-surgical complications, with a focus on maintaining airway patency and stabilizing respiratory function to prevent further deterioration.",
        },
        symptoms: ["Hoarseness", "Stridor", "Neck swelling"],
      },
      management: {
        initialActions: ["Administered high-flow oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway compromise from post-surgical edema",
            ETTubeSize: "6.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Foreign Body Aspiration in the ER for 5-Year-Old Girl",
        name: "Lily Martinez",
        age: 5,
        active: false,
        gender: "Female",
        weight: "20 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 5-year-old girl, was brought to the ER after sudden onset choking while eating peanuts. Examination revealed severe respiratory distress with inspiratory stridor, cyanosis, and diminished breath sounds on the right side. The child appeared anxious and was clutching her throat.",
        vitalSigns: {
          heartRate: "150 bpm",
          respiratoryRate: "42 breaths/min",
          oxygenSaturation: "78%",
          bloodPressure: "95/60 mmHg",
          description:
            "A 5-year-old girl was brought to the emergency room after a sudden choking episode while eating peanuts. She presented with severe respiratory distress, characterized by inspiratory stridor, cyanosis, and diminished breath sounds on the right side. The child appeared visibly anxious and was clutching her throat, indicating acute airway obstruction due to foreign body aspiration.Foreign body aspiration is a medical emergency, especially in young children, as the airway can rapidly become compromised. In this case, the inspiratory stridor suggests partial obstruction of the upper airway, while unilateral diminished breath sounds point to obstruction in the right main bronchus, a common site for aspirated objects due to its anatomical orientation. Cyanosis reflects impaired oxygenation and the urgency of intervention.The immediate priority is restoring airway patency. Back blows and chest thrusts (or abdominal thrusts if the child is older) may be attempted if the obstruction is complete and the child cannot speak or breathe. If the foreign body is not expelled and the child deteriorates, emergency airway management, such as endotracheal intubation or a surgical airway, may be required. Definitive removal typically involves bronchoscopy under controlled conditions.This situation underscores the importance of prompt recognition and management of foreign body aspiration to prevent fatal airway obstruction and ensure a positive outcome.",
        },
        symptoms: [
          "Inspiratory stridor",
          "Cyanosis",
          "Diminished breath sounds",
        ],
      },
      management: {
        initialActions: ["Performed back blows and abdominal thrusts"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Complete airway obstruction",
            ETTubeSize: "4.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Croup Management in the ER for 3-Year-Old Boy",
        name: "James Smith",
        age: 3,
        active: false,
        gender: "Male",
        weight: "14 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 3-year-old boy, presented with a barking cough and difficulty breathing. Examination revealed inspiratory stridor and intercostal retractions, consistent with a diagnosis of croup. His symptoms worsened despite nebulized treatments at home.",
        vitalSigns: {
          heartRate: "140 bpm",
          respiratoryRate: "38 breaths/min",
          oxygenSaturation: "90%",
          bloodPressure: "90/55 mmHg",
          description:
            "A 3-year-old boy presented to the emergency room with a characteristic barking cough and progressive difficulty breathing. Examination revealed inspiratory stridor and intercostal retractions, indicative of upper airway obstruction. His symptoms were consistent with croup (laryngotracheobronchitis) and had worsened despite prior nebulized treatments at home, necessitating urgent medical intervention.Croup is a viral illness that causes inflammation and edema of the upper airway, leading to the hallmark symptoms of a barking cough, stridor, and respiratory distress. In this child, the presence of stridor at rest and retractions indicates moderate to severe croup, requiring aggressive treatment. Initial management involves administering nebulized racemic epinephrine to reduce airway swelling and improve airflow. Dexamethasone, a systemic corticosteroid, is also essential to decrease inflammation and provide longer-lasting symptom relief.Monitoring in the ER is critical, as croup can progress rapidly. If symptoms do not improve or worsen, consideration should be given to further airway support, such as high-flow oxygen or intubation, although this is rare. A calm environment is vital to prevent agitation, which can exacerbate airway obstruction.This case highlights the importance of timely and appropriate treatment in severe croup to alleviate respiratory distress and prevent complications, ensuring the child’s airway remains patent.",
        },
        symptoms: [
          "Barking cough",
          "Inspiratory stridor",
          "Intercostal retractions",
        ],
      },
      management: {
        initialActions: ["Administered nebulized epinephrine"],
        interventions: {
          nebulizedMedications: {
            medication: "Racemic Epinephrine",
            dose: "0.5 mL",
          },
          intubation: {
            indication: "Severe respiratory distress",
            ETTubeSize: "4.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Airway Compromise from Infection for 40-Year-Old Woman",
        name: "Laura Davis",
        age: 40,
        active: false,
        gender: "Female",
        weight: "68 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Type 2 Diabetes",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 40-year-old female, presented with a sore throat, fever, and difficulty breathing. Examination revealed swelling of the oropharynx and inspiratory stridor, indicating possible epiglottitis. The patient appeared anxious and fatigued.",
        vitalSigns: {
          heartRate: "125 bpm",
          respiratoryRate: "30 breaths/min",
          oxygenSaturation: "87%",
          bloodPressure: "115/70 mmHg",
          description:
            "A 40-year-old female presented to the emergency room with a sore throat, fever, and progressive difficulty breathing. Examination revealed swelling of the oropharynx and inspiratory stridor, raising concerns for epiglottitis, a potentially life-threatening condition. The patient appeared visibly anxious and fatigued, reflecting significant respiratory effort and oxygen deprivation.Epiglottitis is a severe infection, often bacterial, that causes inflammation and swelling of the epiglottis, leading to upper airway obstruction. Inspiratory stridor and oropharyngeal swelling in this context are hallmark findings. The patient’s anxiety and fatigue further highlight impending airway compromise, requiring immediate intervention.The primary goal is securing the airway. Given the risk of complete obstruction, a controlled intubation in a setting equipped for difficult airways, such as the operating room, is recommended. If intubation fails or is deemed unsafe, an emergency cricothyrotomy may be necessary. Broad-spectrum intravenous antibiotics and corticosteroids should be initiated promptly to address the underlying infection and reduce inflammation.This case underscores the urgency of recognizing and managing epiglottitis. Early airway stabilization, combined with appropriate antimicrobial therapy and supportive care, is critical to preventing respiratory failure and improving outcomes. Continuous monitoring in a high-acuity setting is essential until the acute phase resolves.",
        },
        symptoms: ["Sore throat", "Fever", "Inspiratory stridor"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway obstruction due to infection",
            ETTubeSize: "7.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Asthma Attack in the ER for 7-Year-Old Girl",
        name: "Olivia Thompson",
        age: 7,
        active: false,
        gender: "Female",
        weight: "25 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Asthma",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 7-year-old girl, was brought to the ER with severe respiratory distress following exposure to pet dander. Examination revealed audible wheezing, use of accessory muscles, and cyanosis.",
        vitalSigns: {
          heartRate: "140 bpm",
          respiratoryRate: "40 breaths/min",
          oxygenSaturation: "84%",
          bloodPressure: "95/65 mmHg",
        },
        symptoms: ["Wheezing", "Accessory muscle use", "Cyanosis"],
      },
      management: {
        initialActions: ["Administered 100% oxygen via non-rebreather mask"],
        interventions: {
          nebulizedMedications: { medication: "Albuterol", dose: "2.5 mg" },
          intubation: {
            indication: "Impending respiratory failure",
            ETTubeSize: "5.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Burn Airway Injury in the ER for 8-Year-Old Boy",
        name: "Ethan Wilson",
        age: 8,
        active: false,
        gender: "Male",
        weight: "30 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, an 8-year-old boy, presented to the ER after a house fire with signs of airway burns, including hoarseness, stridor, and facial burns. He was in severe respiratory distress and had soot in the oropharynx.",
        vitalSigns: {
          heartRate: "130 bpm",
          respiratoryRate: "36 breaths/min",
          oxygenSaturation: "86%",
          bloodPressure: "100/60 mmHg",
        },
        symptoms: ["Hoarseness", "Stridor", "Facial burns"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway swelling from thermal injury",
            ETTubeSize: "5.5 mm (cuffed)",
          },
        },
      },
    },
  },
];

export default scenario;
